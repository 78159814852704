.container{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.tab{
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.row{
    display: flex;
    align-items: center;
    justify-content: center;
}
.title{
    font-size: 50px !important;
    font-weight:900;
    text-shadow: #FFF 2px 2px;
}